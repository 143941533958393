footer {
  display: flex;
  background: rgba(255, 255, 255, 0.4);
  align-items: flex-end;
  padding: 0 var(--space-medium);
  padding-bottom: var(--space-small);
  margin-top: var(--space-large);

  & p {
    margin: unset;
    font-size: var(--type-xx-small);
    max-width: unset;
  }

  & .ofsted-report > img {
    display: none;
    width: 3em;
    margin-right: var(--space-medium);
    margin-top: var(--space-small);

    @media(max-width: 60em) {
      display: unset;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  & nav {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: var(--space-small);

    & > a {
      font-size: var(--type-small);
      font-weight: 500;

      & ~ a {
        margin-left: var(--space-medium);
      }
    }
  }

  & .attribution {
    flex: 1 0 auto;
    font-weight: 500;

    & .icons {
      font-weight: 200;
      font-size: var(--type-small);
    }
  }

  @media(max-width: 40em) {
    flex-direction: column;
    align-items: flex-start;

    & .ofsted-report {
      margin-bottom: var(--space-medium);
    }

    & .attribution {
      align-self: flex-end;
    }
  }

}