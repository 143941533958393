article.term-dates-fees {

  & dl {
    & div {
      margin-bottom: var(--space-large);
    }

    & dt {
      font-weight: 500;
      margin-left: var(--space-medium);
      color: var(--color-blue-d);
    }

    & dd {
      margin-top: var(--space-small);
      margin-left: var(--space-large);

      & em {
        font-weight: 500;
      }
    }
  }

  & .download-term-dates {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    max-width: 35em;


    & .icon {
      height: 2em;
      width: auto;
      margin-right: var(--space-medium);
    }
  }
}