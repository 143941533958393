article.meals {
  & .download-menu {
    & .icon {
      height: 1.5em;
      width: auto;
      position: relative;
      top: 0.4em;
    }
  }

  & header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8.8em, 1fr));
    grid-gap: 1em;
    max-width: 60%;
    margin: auto;

    & > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: scale-down;
      overflow: hidden;
      border-radius: 1em;
    }
  }

  & h3 {
    max-width: 25em;
    margin: auto;
  }
}