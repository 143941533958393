@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Black Italic'), local('WorkSans-BlackItalic'),
        url('WorkSans-BlackItalic.woff2') format('woff2'),
        url('WorkSans-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
        url('WorkSans-Bold.woff2') format('woff2'),
        url('WorkSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Black'), local('WorkSans-Black'),
        url('WorkSans-Black.woff2') format('woff2'),
        url('WorkSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Bold Italic'), local('WorkSans-BoldItalic'),
        url('WorkSans-BoldItalic.woff2') format('woff2'),
        url('WorkSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraBold'), local('WorkSans-ExtraBold'),
        url('WorkSans-ExtraBold.woff2') format('woff2'),
        url('WorkSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraLight'), local('WorkSans-ExtraLight'),
        url('WorkSans-ExtraLight.woff2') format('woff2'),
        url('WorkSans-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Italic'), local('WorkSans-Italic'),
        url('WorkSans-Italic.woff2') format('woff2'),
        url('WorkSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraLight Italic'), local('WorkSans-ExtraLightItalic'),
        url('WorkSans-ExtraLightItalic.woff2') format('woff2'),
        url('WorkSans-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraBold Italic'), local('WorkSans-ExtraBoldItalic'),
        url('WorkSans-ExtraBoldItalic.woff2') format('woff2'),
        url('WorkSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Light Italic'), local('WorkSans-LightItalic'),
        url('WorkSans-LightItalic.woff2') format('woff2'),
        url('WorkSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Medium Italic'), local('WorkSans-MediumItalic'),
        url('WorkSans-MediumItalic.woff2') format('woff2'),
        url('WorkSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Light'), local('WorkSans-Light'),
        url('WorkSans-Light.woff2') format('woff2'),
        url('WorkSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Medium'), local('WorkSans-Medium'),
        url('WorkSans-Medium.woff2') format('woff2'),
        url('WorkSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
        url('WorkSans-SemiBold.woff2') format('woff2'),
        url('WorkSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Thin'), local('WorkSans-Thin'),
        url('WorkSans-Thin.woff2') format('woff2'),
        url('WorkSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Regular'), local('WorkSans-Regular'),
        url('WorkSans-Regular.woff2') format('woff2'),
        url('WorkSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans SemiBold Italic'), local('WorkSans-SemiBoldItalic'),
        url('WorkSans-SemiBoldItalic.woff2') format('woff2'),
        url('WorkSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Thin Italic'), local('WorkSans-ThinItalic'),
        url('WorkSans-ThinItalic.woff2') format('woff2'),
        url('WorkSans-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

