contact-panel {
  & contact-info > ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 16em;
    margin: auto;

    & li {
      display: flex;
      align-items: center;
      margin: var(--space-small) 0;
      font-weight: 400;
    }
  }

  & .icon {
    height: 2em;
    width: 2em;
    padding-right: var(--space-medium);
    fill: var(--color-red-d);

    & .background {
      stroke: white;
    }
  }
}