body > header.mobile {
  display: none;
}

header-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 5em;
    object-fit: scale-down;
    padding: 0 var(--space-medium);
  }
}

body > header {
  position: relative;

  & figure {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    & img {
      height: calc(1vw * 9);
      object-fit: scale-down;
      object-position: 50% 0%;
      margin-right: calc(5vw * 3.5);
    }
  }

  & contact-info {
    position: absolute;
    right: var(--space-medium);
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    & > ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      & li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9em;

        & ~ li {
          margin-top: var(--space-small);
        }
      }

      & a {
        text-decoration: none;
        font-size: var(--type-x-small);
        font-family: var(--font-sans-serif-secondary);
        font-weight: 300;
        letter-spacing: 0.02em;
      }

      & .icon {
        height: 1em;
        width: 1em;
        padding-right: var(--space-small);
        fill: var(--color-red-d);

        & .background {
          stroke: white;
        }
      }
    }
  }
}

@media(max-width: 60em) {
  body > header {
    display: none;
  }

  body > header-logo {
    display: none;
  }

  body > header.mobile {
    display: block;
    padding-bottom: var(--space-medium);

    & header-logo {
      position: absolute;
      padding-top: var(--space-small);

      & img {
        height: 10vw;
      }
    }

    & > figure {
      float: center;
      width: 100%;

      & img {
        height: 10vw;
        margin-right: unset;
      }
    }

    @media(max-width: 40em) {
      padding-bottom: var(--space-small);

      & header-logo {
        position: unset;

        & img {
          height: 18vw;
        }
      }

      & > figure {
        display: none;
      }
    }
  }
}

#hamburger-menu-checkbox ~ label {
  position: absolute;
  display: none;
  top: var(--space-medium);
  right: var(--space-medium);

  @media(max-width: 60em) {
    display: inherit;
  }
}