[am-Panel] {
  border-radius: 1em;
  background: var(--color-magenta-l);
  padding: var(--space-x-small) var(--space-medium);

  & p {
    font-weight: 500;
    max-width: 40em;
    margin-left: 0;
    margin-right: 0;
  }
}