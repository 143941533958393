body {
  overflow-x: hidden;
  display: grid;
  background: linear-gradient(180deg, rgba(255,255,255,1) 10%, rgba(230,230,255,1) 50%, rgba(224,232,249,1) 60%, rgba(220,238,251,1) 68%, rgba(227,249,229,1) 77%, rgba(255,251,234,1) 85%, rgba(255,232,217,1) 93%, rgba(255,238,238,1) 100%);
  min-height: 100vh;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: var(--header-height) auto var(--footer-height);
  grid-template-areas:
    "logo header"
    "aside main"
    "footer footer";
    animation: fadeIn ease 0.6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

header-logo {
  grid-area: logo;
}

header {
  grid-area: header;
}

footer {
  grid-area: footer;
}

aside {
  grid-area: aside;
}

main {
  grid-area: main;
  background: rgba(255, 255, 255, 0.8);
  margin-right: var(--space-small);
  margin-left: var(--space-x-small);
  padding: var(--space-medium);
  border-radius: 2em;

  border: 3px solid var(--color-blue-d);

  & > article {
    margin-top: var(--space-medium);
  }
}

video {
  display: block;
  width: 80%;
  margin: auto;
}

hr {
  margin: var(--space-large) auto;
  width: 70%;
  color: var(--color-indigo-d);
  background: var(--color-indigo-d);
  border-radius: 1em;
  border-style: solid;
}

.icon {
  height: 1em;
  width: 1em;
  fill: var(--color-red-d);

  & .background {
    stroke: white;
  }
}

.hidden {
  display: none;
}

@media(max-width: 60em) {
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1 1 auto;
  }
}