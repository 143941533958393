article.policies {
  & ul {
    width: auto;
    text-align: center;
    font-weight: 500;

    & li:before {
      content: "\2022";
      color: var(--color-indigo-d);
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}
