.about {
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;

  & .helena {
    display: block;
    margin: auto;
    height: 12em;
    width: auto;
    border-radius: 50%;
  }

  & > img {
    display: block;
    height: 15em;
    width: auto;
    border-radius: 1em;
    object-fit: cover;
    margin: auto;
  }

  & .tapestry-logo {
    height: 3em;
    width: auto;
    display: block;
    margin: auto;
  }
}