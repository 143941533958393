.contact-success {
  & .back {
    display: flex;
    align-items: center;

    & a {
      display: flex;
      align-items: center;
      font-size: var(--type-large);
      margin: auto;
      background: var(--color-link);
      color: white;
      padding: var(--space-small);
      border-radius: 0.6em;
    }

    & svg {
      height: 1em;
      width: auto;
      margin: 0 var(--space-small);
      fill: white;
    }
  }
}
