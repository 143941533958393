body.gallery {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: white;

  --logo-height: 3em;
  --header-height: calc(var(--logo-height) + var(--space-small));

  & header.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 0 !important;
    height: var(--header-height);

    & header-logo {
      position: absolute;
      top: var(--space-small);
      right: var(--space-small);

      & img:not(.icon) {
        height: var(--logo-height) !important;
      }
    }

    & h2 {
      flex: 1 0 auto;
      text-align: center;

      & svg {
        margin-right: var(--space-x-small);
      }
    }

    @media(max-width: 25em) {
      --header-height: 6em;

      & h2 {
        align-self: flex-end;
      }
    }

    & .back {
      display: flex;
      width: auto;
      height: auto;
      align-items: center;
      position: absolute;
      top: var(--space-small);
      left: var(--space-x-small);
      font-size: var(--type-small);

      & svg {
        height: 1em;
        width: auto;
        margin: 0 var(--space-small);
        fill: var(--color-indigo-d);
      }
    }
  }

  & main {
    flex: 1 0 auto;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    & svg.arrow {
      height: 2em;
      width: auto;
      margin: var(--space-large);
      fill: var(--color-orange-d);
    }

    & mobile-nav-arrows {
      display: none;
    }

    & figure {
      flex: 1 1 auto;
      height: calc(100vh - var(--header-height));
      max-height: 100%;
      padding: var(--space-small);
      padding-top: var(--space-medium);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & img:not(.icon) {
        flex: 1 1 auto;
        width: auto;
        max-width: 95vw;
        height: 100%;
        object-fit: scale-down;
        overflow: hidden;
      }

      & figcaption {
        font-family: var(--font-sans-serif-secondary);
        margin-top: var(--space-medium);
        max-width: 35em;
        text-align: center;
      }
    }

    &[data-index='1'] {
      & a.back-arrow {
        visibility: hidden;
      }
    }

    &[data-last-photo='true'] {
      & a.forward-arrow {
        visibility: hidden;
      }
    }

    @media(max-width: 60em) {
      & a.arrow {
        display: none;
      }

      & mobile-nav-arrows {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: var(--space-small);

        & > a.arrow {
          display: block;
        }
      }

      & svg.arrow {
        height: 1.5em;
        margin: var(--space-small);
      }
    }
  }
}

article.gallery {
  & h2 > svg-decorated-text > svg, img {
    margin-right: var(--space-x-small);
  }

  & picture-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8.8em, 1fr));
    grid-gap: 1em;
    max-width: 100%;
    margin-top: var(--space-large);
    margin-bottom: var(--space-small);

    & > * {
      justify-self: center;
      align-self: center;
    }

    & img:not(.icon) {
      max-height: 100%;
      max-width: 100%;
      object-fit: scale-down;
      overflow: hidden;
      border-radius: 1em;
    }
  }

  & .gallery-link {
    display: flex;
    align-items: center;
    font-family: var(--font-sans-serif-secondary);

    & svg {
      fill: var(--color-indigo-d);
      margin-left: var(--space-small);
    }
  }
}