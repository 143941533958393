:root {
  --color-violet: #3F0AA1;
  --color-indigo: #6307C2;
  --color-blue: #079AE7;
  --color-green: #045C0C;
  --color-yellow: #FAA309;
  --color-orange: #EA7317;
  --color-red: #B40404;

  --color-yellow-d: #8D2B0B;
  --color-red-d: #610316;
  --color-blue-d: #002159;
  --color-orange-d: #AD1D07;
  --color-green-d: #07600E;
  --color-indigo-d: #0B1D96;

  --color-blue-x-d: #102A43;

  --color-magenta-l: #FDEBFF;

  --color-shadow: #A39E93;
  --color-text: #27241D;
  --color-link: #03449E;
}