@font-face {
    font-family: 'Love Ya Like A Sister';
    src: local('Love Ya Like A Sister Regular'), local('LoveYaLikeASister-Regular'),
        url('LoveYaLikeASister-Regular.woff2') format('woff2'),
        url('LoveYaLikeASister-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

