article.careers {
  & ul.positions {
    width: auto;
    text-align: center;
    font-weight: 500;

    & li:before {
      content: "\2022";
      color: var(--color-indigo-d);
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  & contact-info {
    display: block;
    margin: auto;
    max-width: 35em;

    & li {
      margin-top: var(--space-small);
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & svg {
        margin-right: var(--space-small);
      }
    }
  }
}