aside nav {
  & li {
    display: block;
    padding: var(--space-small);
    display: flex;
    justify-content: center;

    & a.mobile {
      display: none;
    }

    &:nth-child(1) > a {
      background: var(--color-red);
      margin-top: var(--space-medium);

      @media(max-width: 60em) {
        margin-top: unset;
      }
    }

    &:nth-child(2) > a {
      background: var(--color-orange);
    }

    &:nth-child(3) > a {
      background: var(--color-yellow);
    }

    &:nth-child(4) > a {
      background: var(--color-green);
    }

    &:nth-child(5) > a {
      background: var(--color-blue);
    }

    &:nth-child(6) > a {
      background: var(--color-indigo);
    }

    &:nth-child(7) > a {
      background: var(--color-violet);
    }

    &:nth-child(8) > a {
      background: var(--color-purple);
    }
  }

  & a {
    display: block;
    padding: var(--space-small);
    text-decoration: none;
    width: 7.6em;
    color: white;
    border-radius: 0.5em;
    box-shadow: 3px 3px 5px var(--color-shadow);
    font-family: var(--font-nav-links);
    transition: transform 0.1s linear;
    box-sizing: border-box;

    &:hover {
      transform: translate(2px, 2px);
    }
  }

  & a[data-active='true'] {
    border: 4px solid white;
    pointer-events: none;
    transform: translate(2px, 2px);
  }
}

aside {
  & .ofsted-report > img {
    display: block;
    margin: auto;
    width: 5em;
  }
}

@media(max-width: 60em) {
  aside {
    position: fixed;
    top: 3em;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
    z-index: 1;

    & label[for='hamburger-menu-checkbox'] {
      display: unset;
    }

    &:after {
      transition: opacity .15s ease-in-out;
      position: fixed;
      content: '';
      z-index: -1;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;


      @supports (-webkit-backdrop-filter: none) {
        -webkit-backdrop-filter: blur(30px);
      }

      @supports not (-webkit-backdrop-filter: none) {
        background-color: rgba(255, 255, 255, .92);
      }
    }

    & .ofsted-report > img {
      display: none;
    }

    & nav ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: var(--space-medium);
      align-items: center;
      justify-content: center;

      & > * {
        flex: 1 0 auto;
      }
    }

    & nav li {
      & a {
        display: none;
        width: 11em;
        padding: var(--space-medium);
      }

      & a.mobile {
        display: inherit;
      }
    }
  }

  #hamburger-menu-checkbox:checked ~ aside {
    opacity: 1;
    visibility: visible;
  }

  #hamburger-menu-checkbox:checked ~ label {
    position: fixed;
  }
}
