form {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: stretch;
  justify-content: center;
  width: unset;
  max-width: 15em;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  font-size: var(--type-medium);
  border-radius: 0.6em;
  border: solid 2px var(--color-text);
  padding: var(--space-small);
  outline: none;
  width: 100%;

  &::placeholder {
    font-size: var(--type-small);
    font-family: var(--font-sans-serif-secondary);
  }

  &:focus {
    border-color: var(--color-yellow);
  }
}

text-field-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  font-size: var(--type-small);
  margin-top: 1em;

  & label {
    line-height: 1.8;
    margin-left: 0.6em;
  }
}

textarea {
  height: 8em;
  font-size: var(--type-small);
  resize: none;

  &::placeholder {
    font-size: var(--type-x-small);
  }
}

input[type='submit'] {
  display: block;
  padding: var(--space-small);
  text-decoration: none;
  width: 7.6em;
  color: white;
  border-radius: 0.5em;
  box-shadow: 3px 3px 5px var(--color-shadow);
  font-family: var(--font-nav-links);
  transition: transform 0.1s linear;
  box-sizing: border-box;
  background: var(--color-link);
  border: 4px solid var(--color-link);
  font-size: 1em;
  margin-top: var(--space-large);
  align-self: flex-end;

  &:hover {
    transform: translate(2px, 2px);
  }
}