article.contact {
  & > img {
    display: block;
    height: 15em;
    width: auto;
    border-radius: 1em;
    object-fit: cover;
    margin: auto;
  }

  & contact-panel {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 40em;
    margin: auto;
    font-family: var(--font-sans-serif-secondary);
    line-height: 1.4;
    font-size: var(--type-medium);

    & > * {
      margin: var(--space-medium);
      flex: 1 0 auto;
    }

    & address {
      display: block;
      max-width: 10em;
      font-weight: 500;
      margin-bottom: var(--space-large);
    }

    & contact-info {
      & ul {
        margin: 0;
        max-width: 20em;
      }

      & li {
        margin: var(--space-x-small);

        &:first-child {
          margin-top: 0;
        }
      }
    }

    & .icon {
      height: 1.4em;
      width: auto;
    }
  }

  & iframe {
    display: block;
    height: 50vh;
    width: 90%;
    margin: auto;
    border: 0;
  }
}