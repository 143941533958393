testimonials-container {
  display: flex;
  margin: 0 var(--space-medium);
  flex-wrap: wrap;

  justify-content: center;

  & testimonial-container {
    flex: 1 1 16em;
    max-width: 20em;
    margin: var(--space-small);
    display: flex;
    flex-direction: column;
    font-family: var(--font-sans-serif-secondary);
    background: var(--color-magenta-l);
    padding: var(--space-medium);
    border-radius: 1em;

    & blockquote {
      font-size: var(--type-small);
      line-height: 1.4;
      font-weight: 300;
      flex: 1 0 auto;

      &:before {
        content: '❝ ';
        color: var(--color-blue-x-d);
      }

      &:after {
        content: ' ❞';
        color: var(--color-blue-x-d);
      }
    }

    & quote-source {
      font-size: var(--type-x-small);
      font-weight: bold;
      align-self: flex-end;
      margin-top: var(--space-small);
    }
  }
}