.home {
  & .testimonials-link {
    float: right;
    display: flex;
    align-items: center;

    & svg {
      padding-left: var(--space-small);
      fill: var(--color-blue-x-d);
    }
  }

  & h4 {
    max-width: 30em;
    margin: auto;
  }

  & figure.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    grid-gap: 2em;
    max-width: min(70%, 30em);
    margin: auto;

    & > figure {
      & a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: inherit;
      }

      & img:not(.icon) {
        display: block;
        width: 100%;
        border-radius: 1em;
        aspect-ratio: 1;
        object-fit: cover;
      }

      & figcaption {
        margin-top: var(--space-small);
        font-size: var(--type-large);

        & strong {
          font-family: var(--font-sans-serif-secondary);
          font-weight: 500;
        }
      }
    }
  }

  & .about-link {
    display: flex;
    max-width: 30em;
    margin: auto;
    align-items: center;
    justify-content: center;

    & > a {
      display: block;
      padding: 0 var(--space-x-small);
    }

    & > svg {
      height: 3.5em;
      width: auto;
    }

    @media(max-width: 40em) {
      flex-direction: column;

      & > svg {
        height: 1.5em;
        padding: var(--space-x-small);
      }
    }
  }
}
