article.download-registration-form {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  & svg.icon {
    flex: 1 0 auto;
    height: 1.5em;
    width: auto;
    padding: 0 var(--space-medium);
  }
}
