:root {
  --font-nav-links: 'Love Ya Like A Sister', cursive;
  --font-heading: 'Love Ya Like A Sister', cursive;
  --font-sans-serif-primary: 'Montserrat', sans-serif;
  --font-sans-serif-secondary: 'Work Sans', sans-serif;
}

body {
  font-family: var(--font-sans-serif-primary);
  font-weight: 300;
  color: var(--color-text);
}

a {
  color: var(--color-link);
}

h1 {
  font-size: var(--type-xxx-large);
  font-family: var(--font-heading);
  color: var(--color-orange-d);
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 1.5px;

  & [am-text~="blue"] {
    color: var(--color-blue-x-d);
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-thickness: 1.5px;
    text-decoration-color: var(--color-blue-x-d);
  }
}

h2 {
  font-size: var(--type-xx-large);
  font-family: var(--font-heading);
  color: var(--color-blue-x-d);
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 1.5px;
}

h3 {
  font-size: var(--type-x-large);
  font-family: var(--font-heading);
  color: var(--color-blue-x-d);
  line-height: 1.4;
}

h4 {
  font-family: var(--font-heading);
  font-size: var(--type-large);
  line-height: 1.4;
}

h5 {
  font-family: var(--font-heading);
  font-size: var(--type-medium);
}

h6 {
  font-family: var(--font-heading);
  font-size: var(--type-small);
}

p {
  display: block;
  max-width: 35em;
  margin: var(--space-medium) auto;
  line-height: 1.6;
  text-align: justify;
}

[am-text~="center"] {
  text-align: center;
}

[am-text~="right"] {
  text-align: right;
  justify-content: flex-end;
}

[am-text~="xxx-small"] {
  font-size: var(--type-xxx-small);
}

[am-text~="xx-small"] {
  font-size: var(--type-xx-small);
}

[am-text~="x-small"] {
  font-size: var(--type-x-small);
}

[am-text~="small"] {
  font-size: var(--type-small);
}

[am-text~="medium"] {
  font-size: var(--type-medium);
}

[am-text~="large"] {
  font-size: var(--type-large);
}

[am-text~="x-large"] {
  font-size: var(--type-x-large);
}

[am-text~="xx-large"] {
  font-size: var(--type-xx-large);
}

[am-text~="xxx-large"] {
  font-size: var(--type-xxx-large);
}

[am-text~="underline"] {
  text-decoration-line: underline;
}

a[am-link~="underline-on-hover"] {
  color: var(--color-indigo-d);
  text-decoration: none;

  &:hover {
    text-decoration-line: underline;
  }
}

svg-decorated-text {
  white-space: nowrap;

  & > svg, & > img {
    position: relative;
    top: 0.15em;
    height: 1em;
    z-index: 0;
  }
}

todo-note {
  display: block;
  max-width: 35em;
  margin: var(--space-medium) auto;
  line-height: 1.6;
  font-weight: 500;
  text-align: center;
}
